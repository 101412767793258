<template>
    <ul class="nav nav-tabs nav-fill custom-tabs">
        <li class="nav-item" v-for="(link, i) in links" :key="i">
            <router-link
                :to="link.path"
                class="nav-link d-flex gap-1 align-items-center"
                :class="{ active: link.path === $route.path }"
                >{{ $t(link.label) }}
                <i
                    class="bx bx-loader text-white bx-spin fs-5"
                    v-if="profile.routeTo == link.path && profile.loading"></i>
            </router-link>
        </li>
    </ul>
</template>

<script>
import ui from '@/mixins/ui';
import { mapGetters } from 'vuex';
export default {
    mixins: [ui],
    data() {
        return {
            all_tabs: [
                {
                    label: "money logs (honorlink)",
                    path: "/money-logs/honorlink",
                },
                {
                    label: "total money logs",
                    path: "/asset-log/detailed",
                },
                {
                    label: 'point logs (player)',
                    path: '/log/asset/player/points/all',
                },
                {
                    label: 'money logs (player)',
                    path: '/log/asset/player/balance/all',
                },
                {
                    label: 'money logs (agent)',
                    path: '/log/asset/agent/balance/all',
                },
                {
                    label: 'commissions (1 day)',
                    path: '/asset-log/agent-commissions/day',
                },
                {
                    label: 'commissions (all)',
                    path: '/asset-log/agent-commissions/all',
                },
            ],
            online_tabs: [
                {
                    label: 'store charging',
                    path: '/agent/transaction/deposit',
                },
                {
                    label: 'store exchange',
                    path: '/agent/transaction/withdraw',
                },
                {
                    label: 'member charging',
                    path: '/player/transaction/deposit/online',
                },
                {
                    label: 'member exchange',
                    path: '/player/transaction/withdraw/online',
                },
                {
                    label: 'point logs',
                    path: '/log/asset/player/points/all',
                },
                {
                    label: 'money logs (player)',
                    path: '/log/asset/player/balance/all',
                },
                {
                    label: 'money logs (agent)',
                    path: '/log/asset/agent/balance/all',
                },
                {
                    label: 'monthly report',
                    path: '/report/monthly',
                },
            ],
            offline_tabs: [
                {
                    label: 'store charging',
                    path: '/agent/transaction/deposit',
                },
                {
                    label: 'store exchange',
                    path: '/agent/transaction/withdraw',
                },
                {
                    label: 'point logs',
                    path: '/log/asset/player/points/all',
                },
                {
                    label: 'money logs (player)',
                    path: '/log/asset/player/balance/all',
                },
                {
                    label: 'money logs (agent)',
                    path: '/log/asset/agent/balance/all',
                },
                {
                    label: 'monthly report',
                    path: '/report/monthly',
                },
            ],
        };
    },
    computed: {
        ...mapGetters('auth', ['profile']),
        links() {
            var tabs = [];
            switch (this.player_mode) {
                case 'all':
                    tabs = this.all_tabs;
                    break;
                case 'online':
                    tabs = this.online_tabs;
                    break;
                case 'offline':
                    tabs = this.offline_tabs;
                    break;
                default:
                    tabs = this.all_tabs;
            }
            return tabs;
        },
    },
};
</script>
